import React from 'react'

import { Pages } from '../enums'

import siteData from '../content/data.json'

import DefaultLayout from '../templates/DefaultLayout'
import Section from '../components/Section'
import Container from '../components/Container'
import Paragraph from '../components/Paragraph'

import '../scss/styles.scss'

const Page404 = () => {
  return (
    <DefaultLayout title={siteData.page404.title} activePage={Pages.PAGE_404}>
      <Section>
        <Container>
          <h1 className="title">{siteData.page404.headline}</h1>
          <h2 className="subheadline">{siteData.page404.subheadline}</h2>
          <Paragraph>
            Die von Ihnen aufgerufene Seite existiert leider nicht (mehr).
          </Paragraph>
          <Paragraph>
            Bitte überprüfen Sie die von Ihnen eingegebene URL oder kehren Sie{' '}
            <a href="/">zurück zur Startseite.</a>
          </Paragraph>
        </Container>
      </Section>
    </DefaultLayout>
  )
}

export default React.memo(Page404)
